import "./App.css";
import Footer from "./components/footer";
import Header from "./components/header";
import Homepage from "./pages/homepage";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MobileMenu from "./components/mobileMenu";
import Urunlerimiz from "./pages/urunlerimiz";
import FiyatListe from "./pages/fiyatListe";
import Iletisim from "./pages/iletisim";
import Hakkimizda from "./pages/hakkimizda";

function App() {
  return (
    <Router>
      <MobileMenu />
      <Header />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/urunlerimiz" element={<Urunlerimiz />} />
        <Route path="/fiyat-listeleri" element={<FiyatListe />} />
        <Route path="/iletisim" element={<Iletisim />} />
        <Route path="/hakkimizda" element={<Hakkimizda />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
