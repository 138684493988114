import React from "react";
import "./footer.scss";
import logo from "../../assets/images/header/header-logo.png";
import fb from "../../assets/images/footer/fb.png";
import ig from "../../assets/images/footer/ig.png";
import maps from "../../assets/images/footer/maps.png";

function Footer() {
  return (
    <footer className="d-flex flex-d-col">
      <div className="footer-top-section d-flex full-w justify-s-around">
        <div className="header-logo-div d-flex flex-d-col justify-center align-center">
          <img src={logo} alt="Özen İş Elektrik" />
          <p>ÖZEN İŞ ELEKTRİK</p>
        </div>
        <div className="footer-section d-flex flex-d-col">
          <span>İLETİŞİM</span>
          <p>
            Fevzipaşa Caddesi 42/B
            <br /> Erzincan/Merkez
            <br />{" "}
            <a className="text-dec-none col-w" href="tel:(0446) 214 33 33">
              (0446) 214 33 33
            </a>
            <br />{" "}
            <a
              className="text-dec-none col-w"
              href="mailto:nizamettin@ozeniselektrik.com"
            >
              nizamettin@ozeniselektrik.com
            </a>
          </p>
        </div>
        <div className="footer-section d-flex flex-d-col">
          <span>ÜRÜNLERİMİZ</span>
          <p>
            Bayiliklerimiz
            <br /> Mümessilliklerimiz
            <br /> İthal Markalarımız
            <br /> 24TECH & 24LİGHT
          </p>
        </div>
      </div>
      <div className="footer-bottom-section d-flex full-w align-center justify-s-around">
        <p>
          DESIGNED BY <span>ENERTA SOFTWARE</span>
        </p>
        <div className="footer-social d-flex justify-center align-center">
          <img src={fb} alt="Facebook" />
          <img src={ig} alt="Instagram" />
          <img src={maps} alt="Google Maps" />
        </div>
      </div>
    </footer>
  );
}

export default Footer;
