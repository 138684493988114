import React, { useEffect } from "react";
import "./home-communication.scss";
import image1 from "../../assets/images/homepage/home-com-img.png";
import Inputmask from "inputmask";

function HomeCommunication() {
  useEffect(() => {
    Inputmask({ mask: "0599 999 99 99" }).mask(
      document.getElementById("phone-number")
    );
  }, []);
  return (
    <div className="home-communication-area d-flex justify-center align-center">
      <img src={image1} alt="communication img" />
      <form className="homeCommunication">
        <p className="home-communication-title">
          <span>BİZE ULAŞIN</span>
          <br />
          İletişime Geçin
        </p>
        <div className="home-communication-input-div d-grid">
          <input type="text" maxLength="50" placeholder="Ad Soyad" />
          <input id="phone-number" type="text" placeholder="05__ ___ __ __" />
          <input type="text" maxLength="50" placeholder="E-Mail" />
          <input type="text" maxLength="30" placeholder="Konu" />
        </div>
        <textarea placeholder="Mesajınız..."></textarea>
        <div className="home-form-submit d-flex justify-center align-center">
          GÖNDER
        </div>
      </form>
    </div>
  );
}

export default HomeCommunication;
