import "./urunlerimiz.scss";
import React from "react";
import { bayilik, mumessillik } from "../../helper/urunlerimiz";
import tech24 from "../../assets/images/urunlerimiz/ownProduct/24tech.png";
import light24 from "../../assets/images/urunlerimiz/ownProduct/24light.png";
import cat5 from "../../assets/images/urunlerimiz/ownProduct/cat5.png";
import cat6 from "../../assets/images/urunlerimiz/ownProduct/cat6.png";

function Urunlerimiz() {
  return (
    <>
      <div className="bayiliklerimiz-area">
        <p>
          <b>BAYİLİKLERİMİZ</b>
        </p>
        <div className="bayiliklerimiz-content d-grid">
          {bayilik.map((item) => {
            return (
              <div key={item.id} className="d-flex justify-center align-center">
                <img src={item.img} alt="Bayilikler" />
              </div>
            );
          })}
        </div>
      </div>
      <div className="bayiliklerimiz-area">
        <p>
          <b>MÜMESSİLLİKLERİMİZ</b>
        </p>
        <div className="bayiliklerimiz-content d-grid">
          {mumessillik.map((item) => {
            return (
              <div key={item.id} className="d-flex justify-center align-center">
                <img src={item.img} alt="Bayilikler" />
              </div>
            );
          })}
        </div>
      </div>
      <div className="own-product d-flex flex-d-col justify-center align-center">
        <p>
          <b>KENDİ İTHAL ÜRÜNLERİMİZ</b>
        </p>
        <div className="own-product-24 d-flex justify-center align-center">
          <img src={tech24} alt="24tech" />
          <img src={light24} alt="24light" />
        </div>
        <div className="own-product-cable d-flex align-center justify-center">
          <div className="own-product-cell d-flex flex-d-col align-center justify-center">
            <p>
              <b>CAT-5E</b>
            </p>
            <img src={cat5} alt="cat5" />
            <p>
              CAT.5E Communication Data Cable CATEGORY 5 E AIM : 24 AWG 4 PAIRS
              ISDN : TIA/EIA 568B AS400 : Enhanced TOKEN RING : CAT 5E UTP
              ETHERNET : NETWORK Cable CAT 5E CCA Cat5e UTP CAble CCA
              4x2x0,45mm2 305m/roll Light Gray Color PVC
            </p>
          </div>
          <div className="own-product-cell d-flex flex-d-col align-center justify-center">
            <p>
              <b>CAT-6</b>
            </p>
            <img src={cat6} alt="cat6" />
            <p>
              CAT.6 Communication Data Cable CATEGORY 6 AIM : 23 AWG 4 PAIRS
              ISDN : TIA/EIA 568B AS400 : Enhanced TOKEN RING : CAT 6 UTP
              ETHERNET : NETWORK Cable CAT 6E CCA CAt6 UTP Cable CCA 4x2x0,58mm2
              305m/roll Gray Color PVC
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Urunlerimiz;
