import React from "react";
import "./fiyatListe.scss";
import { mumessillik } from "../../helper/urunlerimiz";
import enter from "../../assets/icons/enter.png";
import download from "../../assets/icons/download.png";

function FiyatListe() {
  return (
    <div className="fiyat-liste-area">
      <p>
        <b>FİYAT LİSTELERİ</b>
      </p>
      {mumessillik.map((item) => {
        return (
          <div
            key={item.id}
            className="fiyat-liste-cell d-flex justify-s-between align-center"
          >
            <img src={item.img} alt="Fiyat Liste" />
            <div className="fiyat-liste-link d-flex align-center">
              <a href="#">https://new.siemens.com/tr/tr.html</a>
              <img src={enter} alt="enter" />
            </div>
            <div className="fiyat-liste-link d-flex align-center">
              <a href="#">indir(PDF)</a>
              <img src={download} alt="download" />
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default FiyatListe;
