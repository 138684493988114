/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState } from "react";
import { stack as Menu } from "react-burger-menu";
import { useNavigate } from "react-router-dom";
import "./mobileMenu.scss";

function MobileMenu() {
  const [isOpen, setOpen] = useState(false);
  const handleIsOpen = (route) => {
    navigate(route);
    setOpen(!isOpen);
  };
  let navigate = useNavigate();
  return (
    <Menu
      isOpen={isOpen}
      onOpen={handleIsOpen}
      onClose={handleIsOpen}
      right
      disableAutoFocus
      pageWrapId={"root"}
      outerContainerId={"root"}
    >
      <a onClick={() => handleIsOpen("/")} className="menu-item">
        Ana Sayfa
      </a>
      <a onClick={() => handleIsOpen("/urunlerimiz")} className="menu-item">
        Ürünlerimiz
      </a>
      <a onClick={() => handleIsOpen("/fiyat-listeleri")} className="menu-item">
        Fiyat Listeleri
      </a>
      <a onClick={() => handleIsOpen("/iletisim")} className="menu-item">
        İletişim
      </a>
      <a onClick={() => handleIsOpen("/hakkimizda")} className="menu-item">
        Hakkımızda
      </a>
    </Menu>
  );
}

export default MobileMenu;
