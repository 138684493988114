import bayilikImg1 from "../assets/images/urunlerimiz/bayilik1.png";
import bayilikImg2 from "../assets/images/urunlerimiz/bayilik2.png";
import bayilikImg3 from "../assets/images/urunlerimiz/bayilik3.png";
import bayilikImg4 from "../assets/images/urunlerimiz/bayilik4.png";
import bayilikImg5 from "../assets/images/urunlerimiz/bayilik5.png";
import bayilikImg6 from "../assets/images/urunlerimiz/bayilik6.png";
import bayilikImg7 from "../assets/images/urunlerimiz/bayilik7.png";
import bayilikImg8 from "../assets/images/urunlerimiz/bayilik8.png";
import mumessillikImg1 from "../assets/images/urunlerimiz/mumessillik1.png";
import mumessillikImg2 from "../assets/images/urunlerimiz/mumessillik2.png";
import mumessillikImg3 from "../assets/images/urunlerimiz/mumessillik3.png";
import mumessillikImg4 from "../assets/images/urunlerimiz/mumessillik4.png";
import mumessillikImg5 from "../assets/images/urunlerimiz/mumessillik5.png";
import mumessillikImg6 from "../assets/images/urunlerimiz/mumessillik6.png";
import mumessillikImg7 from "../assets/images/urunlerimiz/mumessillik7.png";
import mumessillikImg8 from "../assets/images/urunlerimiz/mumessillik8.png";
import mumessillikImg9 from "../assets/images/urunlerimiz/mumessillik9.png";
import mumessillikImg10 from "../assets/images/urunlerimiz/mumessillik10.png";
import mumessillikImg11 from "../assets/images/urunlerimiz/mumessillik11.png";
import mumessillikImg12 from "../assets/images/urunlerimiz/mumessillik12.png";
export const bayilik = [
  { id: 1, img: bayilikImg1 },
  { id: 2, img: bayilikImg2 },
  { id: 3, img: bayilikImg3 },
  { id: 4, img: bayilikImg4 },
  { id: 5, img: bayilikImg5 },
  { id: 6, img: bayilikImg6 },
  { id: 7, img: bayilikImg7 },
  { id: 8, img: bayilikImg8 },
];
export const mumessillik = [
  { id: 1, img: mumessillikImg1 },
  { id: 2, img: mumessillikImg2 },
  { id: 3, img: mumessillikImg3 },
  { id: 4, img: mumessillikImg4 },
  { id: 5, img: mumessillikImg5 },
  { id: 6, img: mumessillikImg6 },
  { id: 7, img: mumessillikImg7 },
  { id: 8, img: mumessillikImg8 },
  { id: 9, img: mumessillikImg9 },
  { id: 10, img: mumessillikImg10 },
  { id: 11, img: mumessillikImg11 },
  { id: 12, img: mumessillikImg12 },
];
