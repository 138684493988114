/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import "./header.scss";
import logo from "../../assets/images/header/header-logo.png";
import location from "../../assets/icons/location.png";
import phone from "../../assets/icons/phone.png";
import mail from "../../assets/icons/mail.png";
import home from "../../assets/icons/home.svg";
import { useNavigate } from "react-router-dom";

function Header() {
  let navigate = useNavigate();
  return (
    <div className="header-area">
      <div className="top-header d-flex justify-s-around align-center">
        <div
          onClick={() => navigate("/")}
          className="header-logo-div d-flex flex-d-col justify-center align-center"
        >
          <img src={logo} alt="Özen İş Elektrik" />
          <p>ÖZEN İŞ ELEKTRİK</p>
        </div>
        <div className="header-location-div d-flex">
          <img src={location} alt="Location" />
          <div className="header-location-right-content">
            <p className="header-title">Erzincan/Merkez</p>
            <p className="header-desc">Fevzipaşa Cad. 42/B Merkez</p>
          </div>
        </div>
        <a href="tel:04462143333" className="header-location-div d-flex">
          <img src={phone} alt="Phone" />
          <div className="header-location-right-content">
            <p className="header-title">(0446) 214 33 33</p>
            <p className="header-desc">Telefon</p>
          </div>
        </a>
        <a
          href="mailto:nizamettin@ozeniselektrik.com"
          className="header-location-div d-flex"
        >
          <img src={mail} alt="Mail" />
          <div className="header-location-right-content">
            <p className="header-title">nizamettin@ozeniselektrik.com</p>
            <p className="header-desc">Mail</p>
          </div>
        </a>
      </div>
      <div className="alt-header d-flex justify-center align-center">
        <img
          onClick={() => navigate("/")}
          className="header-home-icon"
          src={home}
          alt="Home"
        />
        <a
          onClick={() => navigate("/urunlerimiz")}
          className="alt-header-button"
        >
          Ürünlerimiz
        </a>
        <a
          onClick={() => navigate("/fiyat-listeleri")}
          className="alt-header-button"
        >
          Fiyat Listeleri
        </a>
        <a onClick={() => navigate("/iletisim")} className="alt-header-button">
          İletişim
        </a>
        <a
          onClick={() => navigate("/hakkimizda")}
          className="alt-header-button"
        >
          Hakkımızda
        </a>
      </div>
    </div>
  );
}

export default Header;
