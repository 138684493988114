import React from "react";
import "./slider.scss";
import { Fade } from "react-slideshow-image";
import slider1 from "../../assets/images/slider/slider1.png";

function Slider() {
  const FadeProperties = {
    duration: 5000,
    pauseOnHover: true,
    transitionDuration: 500,
    infinite: true,
    arrows: true,
  };
  const images = [slider1, slider1, slider1];
  return (
    <div>
      <Fade {...FadeProperties}>
        {images.map((each, index) => (
          <div key={index} className="each-slide">
            <img className="slide-img" alt="" src={each} />
            {/* <div className="slider-caption">
              <h1>{imageDesc[index].content.header}</h1>
              <p
                dangerouslySetInnerHTML={{
                  __html: imageDesc[index].content.cnt,
                }}
              ></p>
            </div> */}
          </div>
        ))}
      </Fade>
    </div>
  );
}

export default Slider;
