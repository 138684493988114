import React from "react";
import "./hakkimizda.scss";

function Hakkimizda() {
  return (
    <>
      <div className="iletisim-title">
        <p>
          <b>Hakkımızda</b>
        </p>
      </div>
      <div className="hakkimizda-content">
        <p>
          Nizamettin Atıcı mesleğe ilk olarak 1980 yılında çırak olarak
          başlamıştır. Elektriğe ve ticarete olan yoğun ilgisi 1984 yılında
          kendi işyerini açma kararı almasını sağlamıştır.
          <br />
          <br /> Özen-iş Elektrik kurulduğu ilk yıllardan 1995 yılına kadar
          taahhüt işleri yapmıştır bu yılın ilk yarısında pazarlama yapmaya
          karar vermiş olup kaliteden taviz vermeyen sektörde söz sahibi olan
          imalatçı firmalarla yaptığı anlaşmalarla bayilikler almıştır. Almış
          olduğu bu güçlü bayiliklerle kısa sürede bölgesinde söz sahibi olan
          bir firma haline gelmiştir. Geçen her sürede müşteri portföyü gelişmiş
          olan firmamız kaliteye ve müşteri memnuniyetine son derece önem
          vermiştir.
          <br />
          <br /> Özen-İş Elektrik kurulduğu ilk yıllardan günümüze kadar dürüst
          ve sistemli ticareti nedeni ile gerek bayiliğini yaptığı firmalardan
          gerekse de mümessili olduğu firmalardan herzaman takdir toplamıştır.
          Toplamış olduğu bu takdirler doğrultusunda da 2010 yılında pazarlama
          firmasını kendi bünyesinden ÖZELPA (Özen-İş Elektrik Pazarlama)
          elektrik olarak ayırmıştır.
          <br />
          <br /> Halen 250 m2 perakende mağazasında aydınlatma ve avize
          çeşitleri ağırlıklı olmak üzere, 1000 m2 pazarlama mağazasında
          Erzincan, Erzurum, Bayburt, Gümüşhane, Trabzon, Ordu, Sivas gibi
          birçok ilde ve ilçede 750’ ye yakın müşterisine, kapısına kadar
          malzeme teslimi ve düşük fiyat garantisi ile hizmet vermektedir.
        </p>
      </div>
    </>
  );
}

export default Hakkimizda;
