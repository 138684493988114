import React from "react";
import Slider from "../../components/slider";
import "./homepage.scss";
import imkan1 from "../../assets/images/imkanlar/1.png";
import imkan2 from "../../assets/images/imkanlar/2.png";
import imkan3 from "../../assets/images/imkanlar/3.png";
import whyMe1 from "../../assets/images/whyMe/1.png";
import HomeCommunication from "../../components/home-communication";
import { splideSliderImg } from "../../helper/homeSplide";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css/sea-green";
import "@splidejs/react-splide/css/core";

function Homepage() {
  return (
    <div>
      <Slider />
      <div className="imkanlar-div">
        <span>İMKANLARIMIZ</span>
        <div className="imkanlar-card-area">
          <div className="imkanlar-card">
            <img src={imkan1} alt="İmkanlarımız" />
            <div className="imkanlar-desc">
              <p>
                <span>Hızlı Tedarik</span>
                <br />
                Gravida sodales condimentum pellen tesq accumsan orci quam
                sagittis sapie
              </p>
            </div>
          </div>
          <div className="imkanlar-card">
            <img src={imkan2} alt="İmkanlarımız" />
            <div className="imkanlar-desc">
              <p>
                <span>Kaliteli Ürünler</span>
                <br />
                Gravida sodales condimentum pellen tesq accumsan orci quam
                sagittis sapie
              </p>
            </div>
          </div>
          <div className="imkanlar-card">
            <img src={imkan3} alt="İmkanlarımız" />
            <div className="imkanlar-desc">
              <p>
                <span>Uygun Fiyatlar</span>
                <br />
                Gravida sodales condimentum pellen tesq accumsan orci quam
                sagittis sapie
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="why-me-area">
        <img src={whyMe1} alt="Neden biz" />
        <div className="why-me-desc-area">
          <p>
            <span className="why-me-title">NEDEN BİZ?</span>
            <br />
            <br />
            <span className="why-me-sub-title">40 Yıllık Tecrübe</span>
            <br />
            <br />
            <br />
            Reprehenderit, odio laboriosam? Blanditiis quae ullam quasi illum
            minima nostrum perspiciatis error consequatur sit nulla.
          </p>
          <div className="why-me-sub-desc">
            <p>
              <span className="why-me-sub-desc-title">Expert in Builings</span>
              <br />
              Lorem ipsum dolor sit amet.
              <br />
              <br />
              <span className="why-me-sub-desc-title">Modern Design</span>
              <br />
              Lorem ipsum dolor sit amet.
              <br />
              <br />
              <span className="why-me-sub-desc-title">
                Leading In Floor Planning
              </span>
              <br />
              Lorem ipsum dolor sit amet.
            </p>
          </div>
        </div>
      </div>
      <HomeCommunication />
      <div className="own-products">
        <p className="home-communication-title">
          <span>ÜRÜNLERİMİZ</span>
          <br />
          BAYİLİKLERİMİZ & MÜMESSİLLİKLERİMİZ
        </p>
        <Splide
          options={{
            type: "loop",
            perPage: 3,
            perMove: 1,
            arrows: false,
            rewind: true,
            rewindSpeed: 1000,
            speed: 10000,
            autoplay: true,
            pagination: false,
            pauseOnHover: false,
            pauseOnFocus: false,
            breakpoints: {
              650: {
                perPage: 1.5,
              },
            },
          }}
          aria-label="favorite"
        >
          {splideSliderImg.map((item) => {
            return (
              <SplideSlide key={item.id}>
                <div className="splideCell">
                  <img src={item.img} alt="Ürünler" />
                </div>
              </SplideSlide>
            );
          })}
        </Splide>
      </div>
    </div>
  );
}

export default Homepage;
