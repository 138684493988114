import React from "react";
import "./iletisim.scss";
import facebook from "../../assets/icons/faceOrange.png";
import instagram from "../../assets/icons/instagramOrange.png";
import maps from "../../assets/icons/mapsOrange.png";
import HomeCommunication from "../../components/home-communication";
import cat5 from "../../assets/images/urunlerimiz/ownProduct/cat5.png";
import cat6 from "../../assets/images/urunlerimiz/ownProduct/cat6.png";

function Iletisim() {
  return (
    <>
      <div className="iletisim-title">
        <p>
          <b>İletişim</b>
        </p>
      </div>
      <div className="iletisim-page-table-div">
        <table
          cellPadding={10}
          cellSpacing={10}
          className="iletisim-page-table"
        >
          <tbody>
            <tr>
              <th>
                <b>İSİM</b>
              </th>
              <th>
                <b>ÜNVAN</b>
              </th>
              <th>
                <b>TELEFON</b>
              </th>
              <th>
                <b>ADRES</b>
              </th>
            </tr>
            <tr>
              <td>NİZAMETTİN ATICI</td>
              <td>FİRMA SAHİBİ</td>
              <td>+90 532 427 03 78</td>
              <td>ERZİNCAN/MERKEZ</td>
            </tr>
            <tr>
              <td>MURAT ERCAN</td>
              <td>MAĞAZA KOORDİNATÖRÜ</td>
              <td>+90 544 273 13 57</td>
              <td>ERZİNCAN/MERKEZ</td>
            </tr>
            <tr>
              <td>EMRE ATICI</td>
              <td>AYDINLATMA MAĞAZASI KOORDİNATÖRÜ</td>
              <td>+90 530 487 24 07</td>
              <td>ERZİNCAN/MERKEZ</td>
            </tr>
            <tr>
              <td>ENES ATICI</td>
              <td>KOORDİNATÖR</td>
              <td>+90 530 791 24 00</td>
              <td>ERZİNCAN/MERKEZ</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="d-flex justify-center align-center iletisim-social">
        <img src={facebook} alt="Facebook" />
        <img src={instagram} alt="Instagram" />
        <img src={maps} alt="Google Maps" />
      </div>
      <HomeCommunication />
      <div className="own-product-cable d-flex align-center justify-center">
        <div className="own-product-cell d-flex flex-d-col align-center justify-center">
          <p style={{ textDecoration: "underline" }}>
            <b>ÖZEN İŞ ELEKTRİK</b>
          </p>
          <p>
            Fevzipaşa Caddesi No:42/b <br />
            Erzincan/Merkez
          </p>
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12269.589238288314!2d39.479257036693014!3d39.75318707116254!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40700ccc173bc679%3A0x941bdcbb859ca19f!2zw5Z6ZW4txLDFnyBFbGVrdHJpaw!5e0!3m2!1str!2str!4v1674996309422!5m2!1str!2str"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className="own-product-cell d-flex flex-d-col align-center justify-center">
          <p style={{ textDecoration: "underline" }}>
            <b>ÖZEN İŞ AYDINLATMA</b>
          </p>
          <p>
            Ali Kemali Caddesi MerPlaza No:1/Z2
            <br /> Erzincan/Merkez
          </p>
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12269.535320453331!2d39.48195973669314!3d39.75348977116193!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40700ccbf0c17055%3A0xb1982eae825e1d42!2zw5Z6ZW4txLDFnyBFbGVrdHJpayBBeWTEsW5sYXRtYQ!5e0!3m2!1str!2str!4v1674996450779!5m2!1str!2str"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </>
  );
}

export default Iletisim;
